import React from "react";
import { useRef, useState, useEffect } from "react";
import {
  Stack,
  IStackTokens,
  IStackStyles,
} from "@fluentui/react";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import { Label } from "@fluentui/react/lib/Label";
import Typed from "typed.js";
import ReactAnime from "react-animejs";
import cake from "./cake.svg";
import "./App.css";

const stackTokens: IStackTokens = { childrenGap: 50 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: "330px",
    margin: "0 auto",
    textAlign: "center",
    color: "#605e5c",
  },
};

const options: IChoiceGroupOption[] = [
  { key: "vacation", text: "度假", iconProps: { iconName: "Vacation" } },
  { key: "work", text: "升职", iconProps: { iconName: "WorkforceManagement" } },
  { key: "smile", text: "笑口常开", iconProps: { iconName: "Emoji" } },
];

export const App: React.FunctionComponent = () => {
  const el = useRef(null);
  const typed = useRef(null);
  const [showQue, setShowQue] = useState(false);
  const [showVioce, setShowVioce] = useState(false);
  const { Anime } = ReactAnime;

  useEffect(() => {
    const options = {
      strings: [
        "<i>今天是</i>^1000 文成公主入藏之日",
        "<i>今天是</i>^1000 摩洛哥王国宣布独立之日",
        "<i>今天是</i>^1000 第三国际成立之日",
        "<i>今天是</i>^1000 美国发射宇宙飞船对木星进行考察之日",
        "<i>今天是</i>^1000 <strong>你的生日</strong>🎂🎁",
      ],
      typeSpeed: 100,
      backSpeed: 50,
      cursorChar: "_",
      onComplete: (self) => {
        self.cursor.remove();
        setShowQue(true);
      },
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destory();
    };
  }, []);

  return (
    <>
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        styles={stackStyles}
        tokens={stackTokens}
      >
        <div className="App-neumorphism">
          <span className="App-blessing" ref={el}></span>
          {showQue && (
            <Anime
              initial={[
                {
                  targets: "#box",
                  translateY: 30,
                  opacity: [0, 1],
                  easing: "linear",
                },
              ]}
            >
              <div id="box">
                <Label className="App-label">选择你的一个愿望</Label>
                <ChoiceGroup
                  className="App-choice"
                  defaultSelectedKey="vacation"
                  options={options}
                />
                <div className="App-button">
                  <button
                    onClick={() => {
                      let audioPlayer = document.getElementById("audio") as HTMLMediaElement;
                      audioPlayer.play();
                      setShowVioce(true);
                    }}
                  >
                    <span>选好点我</span>
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 74 74"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="37"
                        cy="37"
                        r="35.5"
                        stroke="black"
                        strokeWidth="3"
                      ></circle>
                      <path
                        d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
                        fill="black"
                      ></path>
                    </svg>
                  </button>
                </div>
                <audio
                  id="audio"
                  src={require("./happy-birthday-2022.mp3")}
                />
                {showVioce && (
                  <Anime
                    initial={[
                      {
                        targets: "#voice",
                        opacity: [0, 1],
                        easing: "linear",
                        duration: 3000,
                      },
                    ]}
                  >
                    <div id="voice" className="App-voice">
                      当然是祝你全都实现啦，除此之外还要祝你身体健康、事事顺心...
                      <i>
                        <strong>Happy Birthday!!</strong>
                      </i>
                    </div>
                  </Anime>
                )}
              </div>
            </Anime>
          )}
        </div>
        <img className="App-cake" src={cake} alt={cake}></img>
      </Stack>
    </>
  );
};
